import Sidebar from "./Sidebar";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard/Dashboard";
import Login from "./pages/Login/Login";
import { gettoken } from "./Localstorage/Store";
import Meditationlist from "./pages/Meditation/Meditationlist/Meditationlist";
import Viewmwditation from "./pages/Meditation/Viewmeditation/Viewmwditation";
import Addmeditation from "./pages/Meditation/Addmeditation/Addmeditation";
import Editmeditation from "./pages/Meditation/Editmeditation/Editmeditation";
import Userlist from "./pages/User/Userlist/Userlist";
import Adduser from "./pages/User/Adduser/Adduser";
import Viewuser from "./pages/User/Viewuser/Viewuser";
function App() {
  const token = gettoken();
  return (
    <div className="app">
      <Router>

      <Sidebar>
        <Routes>
         <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={token ? <Dashboard /> : <Login />} />
          {/* user pages */}
          <Route path="/userlist" element={token ? <Userlist /> : <Login />} />
          <Route path="/add-user" element={token ? <Adduser /> : <Login />} />
          <Route path="/view-user" element={token ? <Viewuser /> : <Login />} />
          {/* meditation pages */}
          <Route path="/meditationlist" element={token ? <Meditationlist /> : <Login />} />
          <Route path="/view-meditation" element={token ? <Viewmwditation /> : <Login />} />
          <Route path="/add-meditation" element={token ? <Addmeditation /> : <Login />} />
          <Route path="/edit-meditation" element={token ? <Editmeditation /> : <Login />} />
          <Route path="*" element={<> not found</>} />
        </Routes>
      </Sidebar>
  
    </Router>
    </div>
  );
}

export default App;
