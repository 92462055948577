import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import token from './state/token'
import { adminApi } from './api/adminapi'
import { meditationApi } from './api/meditationapi'
import { meditationCategoryApi } from './api/meditation-category-api'

export const store = configureStore({
  reducer: {
    token:token,
    [adminApi.reducerPath]: adminApi.reducer,
    [meditationApi.reducerPath]: meditationApi.reducer,
    [meditationCategoryApi.reducerPath]: meditationCategoryApi.reducer,

  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(adminApi.middleware).concat(meditationApi.middleware).concat(meditationCategoryApi.middleware),
})

setupListeners(store.dispatch)